<template>
    <div class="case-conent catalogue">
        <!--左侧科目基本信息-->
        <div class="catalogueLf">
            <div class="inf-01">
                <div class="inf-back" @click="ToCourseware()"></div>
                <div class="triangle"></div>
                <div class="book-Info">

                    <img v-if="queryResourcelistData.cover !=null" :src="queryResourcelistData.cover" alt="">
                    <img v-else src="../../assets/images/iconFm2.png" alt="">
                    <div class="T-01">{{ queryResourcelistData.name }}</div>
                    <div class="T-02">
                        <div class="d-rg">
                            <el-tooltip class="item" effect="dark" :content="'出版社：'+queryResourcelistData.press"
                                        placement="top">
                                <span>出版社：{{queryResourcelistData.press}}</span>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div class="inf-02">
                <div class="inf-time">上架时间：{{ queryResourcelistData.time }}</div>
                <div class="inf-j"></div>
                <div class="inf-cone">
          <span v-if="queryResourcelistData.introduction !=null">
            {{ queryResourcelistData.introduction }}
          </span>
                    <span v-else>
            暂无
          </span>
                </div>
            </div>
            <!--知识拓扑基本信息-->
            <div class="inf-score">
                <div class="name">知识拓扑</div>
                <div class="score-list" v-for="(item,index) in scoReData" :key="index" @click="scoReClick(item,index)" :class="index == currentIndex ? 'navcolor' : ''">
                   <div class="sc-name" :title="item.name">
                       {{item.name}}
                   </div>
                   <!--未学习-->
                   <div class="sc-Jd" v-if="item.percentage==null">
                       <div class="lT1">未学习</div>
                       <div class="lT2">
                           <el-progress :text-inside="true" :stroke-width="26" :percentage="0"></el-progress>
                       </div>
                   </div>
                </div>
            </div>
        </div>
        <!--右侧目录基本信息-->
        <div class="catalogueRg">
            <div class="tRreeName">{{ queryResourcelistData.name }}</div>
            <div class="inf-03">
                <div class="inf-zT">
                    <h2>原价:{{ queryResourcelistData.totalPrice }}学币</h2>
                    <div class="zT">
                        <span>整本购买更便宜</span>
                        <el-button disabled type="warning" plain size="small" class="btnA" @click="buyOneNo()">{{
                            queryResourcelistData.totalDiscount }}学币
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="tRreeBox">
                <el-tree
                        :data="ResourcelistData"
                        :props="defaultProps"
                        :default-expanded-keys="[2, 3]"
                        node-key="id"
                        default-expand-all
                        :expand-on-click-node="false">
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>
                    <span>
                        <i v-if="data.level == null" class="circle3 el-icon-caret-right1"></i>
                        <i v-if="data.level == 1 " class="circle1 el-icon-caret-right1"></i>
                        <i v-else-if="data.level == 3 &&data.isUnit!=1" class="circle3 el-icon-caret-right1"></i>
                        <i v-else-if="data.level == 2 &&data.isUnit!=1" class="circle3 el-icon-caret-right1"></i>
                        <i v-else-if="data.level == 3 &&data.isUnit!=1" class="circle3 "></i>
                        <i v-else-if="data.level == 4 &&data.isUnit!=1" class="circle3 el-icon-caret-right1"></i>
                        <i v-if="data.level == 1 &&data.isUnit!=3" class="ico-tree-2"><img
                                src="../../assets/images/ico-tree-1.png" alt=""></i>
                        <i v-if="data.isResoure == 1" class="ico-tree-2"><img src="../../assets/images/ico-tree-2.png"
                                                                              alt=""></i>
                    </span>
                    {{ data.resourceName }}
            </span>

            <span>
              <div class="N2">
<!--              <el-button size="mini" style="border: 1px solid #FF9100; background: #FF9100; color: #FFF" round >3个提问</el-button>-->
              </div>
              <div class="N3">
<!--                  <span v-if="data.isResoure==0&&data.isBuy==0&&data.level==2">-->
                     <el-button disabled v-if="data.isPrice === 1 &&  data.isBuy === 0 " size="mini"
                                icon="el-icon-bangzhu"
                                class="btnC"
                                style="border: 1px solid #FF9100; background-color: #fff; color: #FF9100"
                     >{{ data.price }}学币
                     </el-button>
                     <el-button @click="tosNoBuyDetails(data)" v-if="data.isDiagQu === 1"
                                size="mini"
                                icon="el-icon-view"
                                class="btnC"
                                style="background-color: #fff;border: 1px solid #06A8C9; color: #06A8C9"
                     >诊断式学习
                      </el-button>
                      <el-button @click="tosNoBuyDetails(data)" v-if="data.type === 3"
                             size="mini"
                             icon="el-icon-view"
                             class="btnC"
                             style="background-color: #fff;border: 1px solid #06A8C9; color: #06A8C9"
                         >预览自主式学习
                      </el-button>
                  <!--                      <el-button v-if="data.level == 1 &&data.isUnit==1"-->
                  <!--                                 @click="toUnitTest(data)" size="mini"-->
                  <!--                                 class="btnC" style="background-color: #06A8C9;border: 1px solid #06A8C9;color: #FFF"-->
                  <!--                      >单元测试-->
                  <!--                          </el-button>-->
                  <!--                      <el-button v-if="data.isResoure === 1 && data.isBuy == 1"-->
                  <!--                                 @click="tosDetails(data)" size="mini"-->
                  <!--                                 class="btnC" style="background-color: #06A8C9;border: 1px solid #06A8C9;color: #FFF"-->
                  <!--                      >进入学习-->
                  <!--                      </el-button>-->

                  <!--                    <el-button v-if="data.price!=undefined &&data.isBuy==0"  @click="buyOneD(data)" size="mini" icon="el-icon-bangzhu" style="border: 1px solid #FF9100; background: #FFF; color: #FF9100"-->
                  <!--                    >{{data.price}}学币-->
                  <!--                  </el-button>-->





            </div>
            </span>
          </span>
                </el-tree>
            </div>

        </div>


        <el-dialog
                title="提示"
                :visible.sync="IsNuDialog"
                :modal-append-to-body='false'
        >
            <div class="IsNuD">
                <div class="dialogHd">
                    <i class="el-icon-info"></i><span>您是未注册用户，请前往注册并充值学币！</span>
                </div>
                <div class="dialog-footer">
                    <el-button type="primary" @click="OneNo()">确定</el-button>
                </div>
            </div>
        </el-dialog>


    </div>

</template>


<script>
    import {
        queryResourceObj,
        score,
    } from '@/api/tourist'

    export default {
        name: '',
        data() {
            return {
                folderId:'',//目录ID
                scoReData:[],//学历案目录-知识拓扑
                // 当前颜色
                currentIndex: null,

                IsNuDialog: false,
                buyDialog: false,// 购买
                InsuDialog: false,// 学币不足
                buyCgDialog: false,// 购买成功
                queryResourcelistData: {},
                Dprice: null,
                DfolderId: null,
                Did: null,
                ResourcelistData: [],
                total_price: '',// 原价
                total_discount: '',// 现价
                defaultProps: {
                    children: "list",
                    label: "resourceName"
                },
                catalogue: {
                    cover: "http://bookcover.365zuoye.com?bookID=916",
                    name: "《湖北中职语文基础模块上册第四版（GJ）教案》教学设计",
                    time: "2020-12-19",
                    list: [
                        {
                            id: 31887,
                            isResoure: 0,
                            label: "第一单元",
                            buy: 0,
                            uuid: "e11b4af4-3e5c-4c26-8c53-66963bd80c84",
                            list: [
                                {
                                    id: 162051,
                                    isResoure: 1,
                                    isVideo: 0,
                                    buy: 1,
                                    children: [],
                                    label: "第一单元《一 沁园春 · 长沙》",
                                    uuid: "f51ba87b-8a5d-471b-8646-5cbe91df5b61",
                                },
                                {
                                    id: 162052,
                                    isResoure: 1,
                                    isVideo: 0,
                                    children: [],
                                    label: "第一单元《二 爱情诗二首 致橡树》",
                                    buy: 0,
                                    uuid: "2aa7ae0d-9dcc-4c99-993a-a34d5c02f055",
                                },
                                {
                                    id: 162053,
                                    isResoure: 1,
                                    isVideo: 0,
                                    children: [],
                                    label: "第一单元《二 爱情诗二首 我愿意是急流》",
                                    uuid: "f52173b9-62df-4b07-af59-c6943678ac32",
                                }
                            ]
                        }
                    ],
                },

                query: {
                    yearId: null,
                    subject: null,
                    grade: null,
                    term: null,
                    studySection: null,
                    textbookEdition: null,
                    Series: "",
                },
            }
        },
        created() {
            this.EducationCase = this.$route.query.EducationCase;
            this.clickFlag = this.$route.query.clickFlag;
            this.bookId = this.$route.query.bookId;
            this.onTrial = this.$route.query.onTrial;
            this.totalPrice = this.$route.query.totalPrice;
            this.totalDiscount = this.$route.query.totalDiscount;
            this.queryRes()
            this.scoRe()
            this.listObj()
        },
        methods: {
            //返回
            back() {
                this.$router.go(-1)//就可以回到上一页。
                //history.go(-1)//是回到浏览器上一页
            },
            // 跳到目录页面
            ToCourseware() {
                this.$router.push({
                    path: "/mainb/tourCourseware",
                    query: {
                        EducationCase: this.EducationCase,
                        clickFlag: this.clickFlag,
                        onTrial: this.onTrial,
                        stu: this.stu,
                    }
                });
            },

            //学历案目录
            queryRes() {
                let bookId = this.bookId
                queryResourceObj(bookId).then((res) => { // eslint-disable-line no-unused-vars
                    this.queryResourcelistData = res.data
                    this.ResourcelistData = res.data.list

                    // this.ResourcelistData.map((item)=>{
                    //     this.folderId=item.folderId //目录ID
                    //     console.log(this.folderId,'this.folderId')
                    // })
                })
            },
            //学历案目录-知识拓扑
            scoRe() {
                let data = {
                    bookId:parseInt(this.bookId),
                    folderId:parseInt(this.folderId)
                }
                score(data).then((res) => { // eslint-disable-line no-unused-vars
                    this.scoReData = res.data
                    console.log(this.scoReData,'this.scoReData')
                })
            },
            scoRe2() {
                let data = {
                    folderId:parseInt(this.folderId)
                }
                score(data).then((res) => { // eslint-disable-line no-unused-vars
                    this.scoReData = res.data
                    console.log(this.scoReData,'this.scoReData')
                })
            },
            // 学历案目录-知识拓扑
            scoReClick(item,index) {
                this.currentIndex = index;
                this.folderId=item.id
                console.log(item,'88')
               this.scoRe2()
            },
            //未注册
            buyOneNo() {
                this.IsNuDialog = true
            },
            OneNo() {
                this.IsNuDialog = false
                this.tostuLogin() // 跳转到登录
            },
            //购买单个学历案
            toBuyOne() {
                this.InsuDialog = true


            },
            tobuyCg() {
                this.buyCgDialog = false
                this.queryRes()
            },
            // 跳转到充值
            toRecharge() {
                this.$router.push({
                    path: "/mainb/recharge",
                    query: {
                        EducationCase: this.EducationCase,
                    }
                });
            },
            append(data) {
                console.log(data, '11')
            },
            // 点击购买弹框
            BtnbuyDialog(data) {
                console.log(data, '这是点击拿到的数据')
                this.buyDialog = true
            },
            tostuLogin() {
                this.$router.push({
                    path: "/stuLogin",
                    query: {}
                });
            },

            tosNoBuyDetails(data) {
                this.$router.push({
                    path: "/mainb/tourDetails",
                    query: {
                        EducationCase: this.EducationCase,
                        clickFlag: this.clickFlag,
                        bookId: this.bookId,
                        folderId: data.folderId,
                        id: data.resourceId,
                        disabled: 1,
                        NoBuy: 1,
                        onTrial: 1
                    }
                });
            },
            //查询列表
            listObj() {


            },

        },
        components: {
            // 图标

            //注册图标组件名称
        }
    }
</script>
<style lang="scss" scoped>
    .el-dialog {
        width: 500px;
        height: 350px;
    }

    .el-dialog--center {
        height: 350px;
    }

    .IsNuD {
        width: 100%;

        .dialogHd {
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-weight: bold;

            i {
                align-items: center;
                margin-right: 20px;
                color: #fcd278
            }

            span {
            }
        }

        .dialog-footer {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: flex-end;
        }
    }

    .buyCg {
        width: 140px;
        display: flex;
        display: -webkit-flex;
        flex-wrap: wrap;
        margin: auto;
        flex-direction: column;
        height: 50px;
        line-height: 50px;

        i {
            color: #6eb864;
            margin-right: 10px;
            margin-top: 2px;
        }

        span {
        }
    }

    .buyDialogA1 {
        ::v-deep.el-dialog__header {
            padding: var(--el-dialog-padding-primary);
            font-weight: 800;
            height: 46px;
            line-height: 46px;
            box-sizing: border-box;
            padding-left: 16px;
            border-bottom: 0px solid #f2f2f2;
            padding-bottom: 0px;
        }

        ::v-deep.el-dialog__body {
            padding: 0px;
            color: #606266;
            word-break: break-all;
        }

        .buyDialogA1-01 {
            width: 100%;
            border-bottom: 1px solid #f2f2f2;

            p {
                line-height: 30px;
                text-align: center;
                padding: 0;
                margin: 0;
                color: #999;
            }

            .logA {
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-align: center;

                span {
                    color: #FF5A00;
                }
            }

        }

        .buyDialogA1-02 {
            width: 100%;
            padding-top: 20px;
            padding-bottom: 30px;
            border-bottom: 1px solid #f2f2f2;

            p {
                line-height: 30px;
                text-align: center;
                padding: 0;
                margin: 0;
                color: #999;
            }

            .logA {
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-align: center;

                span {
                    color: #000;
                }
            }

        }

        .buyDialogA1-03 {
            width: 100%;
            height: 50px;
            text-align: center;
            background-color: #008DF0;
            padding-top: 20px;
            color: #fff;
        }

        .buyDialogA1-03:hover {
            background-color: #0EBEC6;
        }

        .buyDialogA1-04 {
            width: 100%;
            height: 200px;
            text-align: center;
            padding-top: 20px;
            color: #000;

            .el-icon-success {
                color: #6eb864;
                vertical-align: middle;
                margin-right: 10px;
            }

            .el-icon-warning {
                color: #cf9236;
                vertical-align: middle;
                margin-right: 10px;
            }
        }

        .buyDialogA1-05 {
            width: 100%;
            height: 40px;
            padding-bottom: 30px;
            text-align: center;
            padding-top: 20px;
            color: #fff;
        }
    }

    .case-conent {
        margin: 20px auto;
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        opacity: 0.9;
        display: flex;
        position: relative;

        /*左侧科目基本信息*/
        .catalogueLf {
            width: 304px;
            background: #ccc url("../../assets/images/inf-02.png") no-repeat;
            background-size: cover;
            height: 100%;
            position: absolute;
            //background: #37AEFF;
            text-align: center;
            color: #fff;
            opacity: 1;

            .inf-01 {
                width: 100%;
                height: 203px;
                position: relative;
                background: url("../../assets/images/inf-01.png") no-repeat;
                background-size: cover;

                .inf-back {
                    width: 111px;
                    height: 60px;
                    position: absolute;
                    cursor: pointer;
                    left: 0;
                    background: url("../../assets/images/inf-back.png") no-repeat;
                    background-size: 111px 60px;
                }

                .triangle {
                    width: 15px;
                    height: 30px;
                    position: absolute;
                    background: url("../../assets/images/inf-tg.png") no-repeat;
                    background-size: cover;
                    top: 188px;
                    left: 289px;
                }

                .book-Info {
                    width: 90%;
                    padding-top: 20px;
                    box-sizing: border-box;
                    margin: auto;

                    img {
                        height: 120px;
                        margin: auto;
                    }

                    .T-01 {
                        width: 100%;
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-weight: bold;
                        color: #fcea85;
                    }

                    .T-02 {
                        width: 100%;
                        display: flex;
                        font-weight: 100;
                        color: #fff;

                        .d-rg {
                            width: 100%;
                            text-align: center;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }

            .inf-02 {
                width: 89%;
                margin: auto;

                .inf-time {
                    width: 90%;
                    height: 40px;
                    margin: auto;
                    line-height: 30px;
                    padding-top: 10px;
                    box-sizing: border-box;
                    text-align: center;
                    overflow: hidden;
                    color: #666;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .inf-j {
                    width: 173px;
                    height: 38px;
                    margin-top: 10px;
                    background: url("../../assets/images/inf-j.png") no-repeat;
                    background-size: 173px 38px;
                }

                .inf-cone {
                    width: 90%;
                    height: 80%;
                    overflow-y: auto;
                    color: #666;
                    text-align: left;
                    margin: 20px auto;
                }
            }
            /*知识拓扑基本信息*/
            .inf-score {
                width: 89%;
                margin: auto;
                background-color: #fff;
                color: #666;
                border-radius: 5px;
                padding: 2px 0px;
                box-sizing: border-box;

                .name {
                    width: 90%;
                    height: 40px;
                    margin: auto;
                    line-height: 40px;
                    font-size: 18px;
                    text-align: left;
                    font-weight: bold;
                    box-sizing: border-box;
                    color: #666;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .score-list {
                    width: 94%;
                    padding: 10px 12px;
                    box-sizing: border-box;
                    margin: auto;
                    display: flex;
                    .sc-name {
                       width: 120px;
                       line-height: 60px;
                       padding: 0 6px;
                       box-sizing: border-box;
                       background-color: #D1EEFE;
                       border: 2px solid #1890FF;
                       color: #2A3746;
                       border-radius: 6px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .sc-Jd {
                        width: 140px;
                        padding-left: 10px;
                        box-sizing: border-box;
                        .lT1 {
                            height: 40px;
                            text-align: left;
                            color: #ccc;
                        }
                        .lT2 {

                        }
                    }
                }
                .navcolor {
                    color: red;
                    background-color: #C8F0FF;
                    border-radius: 8px;
                }



                .inf-cone {
                    width: 90%;
                    height: 80%;
                    overflow-y: auto;
                    color: #666;
                    text-align: left;
                    margin: 20px auto;
                }
            }
        }

        /*右侧目录基本信息*/
        .catalogueRg {
            width: 100%;
            padding: 0 0px 0 304px;
            background-color: #fff;
            height: 100%;

            ::v-deep .el-tree-node__content {
                display: flex;
                align-items: center;
                vertical-align: center;
                height: 36px;
                line-height: 36px;
                cursor: pointer;
            }

            ::v-deep .el-icon-caret-right1 {
                border-radius: 50%;
                margin-right: 6px;
                margin-top: -3px;
                vertical-align: middle;
            }

            ::v-deep .el-tree-node__expand-icon {
                position: absolute;
                z-index: 999;
                margin: auto;
                display: flex;
                padding: 3px 3px;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            ::v-deep .el-tree-node__expand-icon {
                cursor: pointer;
                color: #fff;
                /*font-size:12px;*/
                /*transform: rotate(0);*/
                /*transition: transform .3s ease-in-out;*/
            }

            .tRreeName {
                width: 100%;
                height: 45px;
                background-color: #fcd278;
                font-weight: 700;
                color: #000;
                text-align: center;
                line-height: 45px;
            }

            .inf-03 {
                width: 100%;
                height: 64px;
                padding-top: 20px;
                background: url("../../assets/images/inf-03.png") no-repeat center 20px;
                background-size: 273px 64px;
                position: relative;

                .inf-zT {
                    width: 300px;
                    height: 60px;
                    padding-right: 50px;
                    box-sizing: border-box;
                    position: absolute;
                    top: 10px;
                    right: 0px;
                    float: right;

                    h2 {
                        text-decoration: line-through;
                        color: #999;
                        text-align: right
                    }

                    .zT {
                        width: 320px;
                        height: 30px;
                        line-height: 30px;
                        padding-top: 6px;
                        text-align: right;
                        display: flex;

                        ::v-deep.btnA {
                            height: 28px;
                            line-height: 28px;
                            padding: 0 6px;
                        }

                        span {
                            color: #008DF0;
                            margin-right: 20px
                        }
                    }
                }
            }

            .tRreeBox {
                width: 97%;
                margin: 20px auto;
                background-color: #fff;
                padding: 0 60px;
                box-sizing: border-box;
                height: 78%;
                overflow-y: auto;
                color: #000;
                position: relative;
                text-align: center;

                .custom-tree-node {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 100px;
                    box-sizing: border-box;

                    .circle1 {
                        width: 19px;
                        height: 19px;
                        line-height: 19px;
                        border-radius: 50%;
                        margin-right: 10px;
                        margin-top: -3px;
                        text-align: center;
                        justify-content: center !important;
                        align-items: center !important;
                        text-align: center !important;
                        background: #fcd278;
                        color: #fff;

                        img {
                            width: 16px;
                            height: 16px;
                            vertical-align: middle;
                            margin-top: 0px;
                            margin-right: 10px;
                        }
                    }

                    .circle2 {
                        width: 19px;
                        height: 19px;
                        line-height: 19px;
                        border-radius: 50%;
                        margin-right: 10px;
                        text-align: center;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        background: #0090DA;
                        color: #fff;

                        img {
                            width: 16px;
                            height: 16px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            vertical-align: middle;
                            margin-top: 0px !important;
                            margin-right: 10px;
                        }
                    }

                    .circle3 {
                        width: 19px;
                        height: 19px;
                        line-height: 19px;
                        border-radius: 50%;
                        margin-right: 10px;
                        text-align: center;
                        justify-content: center !important;
                        align-items: center !important;
                        text-align: center !important;
                        background: rgba(255, 104, 32, 1);
                        color: #fff;
                    }

                    .ico-tree-2 {
                        width: 19px;
                        height: 19px;
                        line-height: 19px;
                        border-radius: 50%;
                        margin-right: 10px;
                        text-align: center;
                        justify-content: center !important;
                        align-items: center !important;
                        text-align: center !important;

                        img {
                            width: 16px;
                            height: 16px;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }


    }

</style>
